













































import { Component, Vue } from "vue-property-decorator";

@Component
class Carousel extends Vue {
  items = [
    {
      title: "Будьте в курсе",
      subtitle:
        "Просматривайте информацию по заключенным договорам и выгружайте реализацию"
    },
    {
      title: "Передавайте показания",
      subtitle:
        "Передавайте показания и просматривайте историю по приборам учета"
    },
    {
      title: "Анализируйте данные",
      subtitle:
        "Стройте отчеты, анализируйте объемы потребления и обороты за период",
    },
  ];
}

export default Carousel;
